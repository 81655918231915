
@import url('https://fonts.googleapis.com/css2?family=Pacifico');


.App {
  text-align: center;
  font:100;
  color:rgb(9, 9, 22)
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.header {
  font-size: 500px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #7e8289;
  font-family: 'Pacifico', sans-serif;
  width: 100%;
  height: 150px;
  
  

}
.App-services {
  background-color: #7e8289;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(16, 13, 13);
}

.App-link {
  color: #61dafb;
}

.carousel-text p {
  font-size: 30px;
  text-shadow: -1px 1px 2px #000,
    1px 1px 2px #000,
    1px -1px 0 #000,
    -1px -1px 0 #000;
    font-weight: bold;
}

.carousel-img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  
}


#h2 {
  font-size: 10em;
}

.App-Header p {
  font-size: 50px;
}